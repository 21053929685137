<h2 mat-dialog-title>
  {{data.header}}
</h2>
<mat-dialog-content>
  <ng-container *ngIf="data.attributes">
    <table class="table table-striped">
      <tbody [innerHTML]="rows">
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="data.showActivity">
    <h2>{{data.activityHeader}}</h2>
    <table mat-table [dataSource]="(activityData$ | async)" class="table table-striped">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef class="first-data-column data-table-header"> {{column.displayName}} </th>
        <td td mat-cell *matCellDef="let row" class="first-data-column overflow-column-200">
          <ng-container>
            <span title="{{ row[column.name] }}">{{ row[column.name] }}</span>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="data-table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>{{'Close' | translate | async}}</button>
</mat-dialog-actions>

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataTableService } from '../../../../services/services-index';
import { Observable, of, switchMap, iif, catchError, map, takeUntil, tap, throwError, Subject, Subscription } from 'rxjs';
import { AppSelectors } from '../../../../../_store/selector-types';
import * as Models from '../../../../models/models-index';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../_store/app-state.model';
import * as SharedServices from '../../../../../_shared/services/services-index';
import { convertOptionsToDictionary } from '../../panel-utils';
import { ReportsService } from '../../../../services/api/api-index';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionDetailDialogModel } from '../lead-transactions.models';

@Component({
  selector: 'transaction-details-modal',
  templateUrl: './transaction-details-modal.component.html',
  styleUrls: ['./transaction-details-modal.component.scss']
})
export class TransactionDetailsModalComponent implements OnInit, OnDestroy {

  rows?: SafeHtml;
  columns: Models.Column[];
  displayedColumns: string[];
  activityData$: Observable<MatTableDataSource<any>> = of(undefined);
  destroySubject = new Subject<void>();
  destroyed$ = this.destroySubject.asObservable();

  constructor(
    private store$: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionDetailDialogModel,
    private sanitizer: DomSanitizer,
    private spinnerService: SharedServices.SpinnerService,
    private filterService: SharedServices.FilterService,
    private reportService: ReportsService
  ) {
  }

  ngOnInit(): void {
    this.generateDetailRows();

    if (this.data.showActivity) {
      this.loadDataSet(this.data.activityDataset);
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private generateDetailRows(): void {
    const { attributes } = this.data;

    let result = '';
    let i = 0;

    attributes.forEach((value, key, map) => {
      if (i % 2 == 0) {
        // new row every other column
        result += '<tr><td><strong>' + key + '</strong></td><td>' + value + '</td>'

        // consider the first column might be the last and close the row out
        if (i + 1 > attributes.size - 1) {
          result += '<td colspan="2">&nbsp;</td></tr>'
        }

        i++;
        return;
      }

      // close out the row on odd columns
      result += '<td><strong>' + key + '</strong></td><td>' + value + '</td></tr>'
      i++;
    });

    this.rows = this.sanitizer.bypassSecurityTrustHtml(result);
  }

  private loadDataSet(dataSetName: string): void {
    this.activityData$ =
      this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
        switchMap(routeData => this.getReportViewDataSet(routeData.reportName, dataSetName))
      )
    ;
  }

  private getReportViewDataSet(reportName: string, dataSetName: string): Observable<MatTableDataSource<any>> {
    this.spinnerService.show();
    return this.filterService.getReportViewFilterRequestModel(reportName).pipe(
      takeUntil(this.destroyed$),
      map(requestModel => ({ ...requestModel, dataSets: [dataSetName] })),
      switchMap(requestModel => this.reportService.getDataSet(requestModel)),
      map((dataSets: Models.DataSet[]) => {
        if (!dataSets || dataSets.length == 0)
          throw new Error('No data sets returned from the API');

        const dataSet = dataSets[0];
        this.columns = dataSet.columns;
        this.displayedColumns = dataSet.columns.map(c => c.displayName);
        return new MatTableDataSource(this.toRecords(dataSet));
      }),
      tap(_ => this.spinnerService.hide()),
      catchError(err => {
        appInsights.trackException(err);
        // Handle the error and return a safe value or an empty Observable
        // Example: return of({} as Models.DataSet);
        // Or rethrow the error if you want to handle it further up the chain
        return throwError(() => err);
      })
    );
  }

  private toRecords(dataSet: Models.DataSet): any[] {
    const columnNames = dataSet.columns.map(c => c.name);
    const records = [];

    dataSet.rows.forEach(row => {
      const record = {};
      let foundShow = false;
      for (let i = 0; i < columnNames.length; i++) {
        if (columnNames[i] == 'show') {
          foundShow = true;
        }
        record[columnNames[i]] = row[i].label ?? row[i].value;
      }

      if (!foundShow) {
        record['show'] = true;
      }

      records.push(record);
    });

    return records;
  }
}

